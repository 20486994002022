import React from 'react';
import ListOrders from './ListOrders';
import DatePicker from '../../../components/DatePicker';
import { Container, Grid, makeStyles, Button, Card } from '@material-ui/core';
import Page from '../../../components/Page';

const useStyles = makeStyles({
	root: {
		height: '100%',
	},
});

const DateListOrders = ({ className, ...rest }) => {
	const classes = useStyles();
	return (
		<Page className={classes.root}>
			<Container maxWidth={false}>
				<Grid container spacing={3} justify="center">
					<Grid item lg={8} md={12} xl={9} xs={12}>
						<ListOrders />
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
};

export default DateListOrders;
