import React, { useState, useContext ,useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
	Box,
	Button,
	Container,
	Grid,
	Link,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import Page from '../../components/Page';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Constants from '../../common/Constants';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		height: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
}));

const RegisterPasswordView = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies();

	const [uid,setUid] = useState();
	useEffect(() => {
		selectUid();
	}, [])

	function selectUid(){
		Swal.showLoading();
		axios
			.post(
				Constants.URL,
				{

				},
				{
					headers: {
						if_id: Constants.PREPAY_SELECTUID,
						'Content-Type': 'application/json',
						token: cookies.token,
					},
				}
			)
			.then(function (response) {
				if (response.data.result_code != '0000') {
					swal({
						text: response.data.result_message,
						button: {
							text: '확인',
						},
					});
					if (response.data.result_code == '1004') {
						removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
					}
					Swal.close();
				} else {
					setUid(response.data.uid);
					Swal.close();
				}
			})
			.catch(function (error) {
				swal({
					text: '미지정 오류',
					button: {
						text: '확인',
					},
				});
				Swal.close();
			});
	}

	function registPassword(value) {
		if (!uid) {
			swal({
				text: 'UID를 확인해주세요',
				button: {
					text: '확인',
				},
			});
			return false;
		}
		if (!value.password) {
			swal({
				text: '비밀번호를 확인해주세요',
				button: {
					text: '확인',
				},
			});
			return false;
		}
		if (!value.passwordNew) {
			swal({
				text: '변경 비밀번호를 확인해주세요',
				button: {
					text: '확인',
				},
			});
			return false;
		}
		if (!value.passwordNew2) {
			swal({
				text: '변경 비밀번호를 확인해주세요',
				button: {
					text: '확인',
				},
			});
			return false;
		}
		if (value.passwordNew !== value.passwordNew2) {
			swal({
				text: '변경 비밀번호가 일치하지 않습니다.',
				button: {
					text: '확인',
				},
			});
			return false;
		}
		Swal.showLoading();
		axios
			.post(
				Constants.URL,
				{
					uid: uid,
					pw: value.password,
					pwNew: value.passwordNew,
					pwNew2: value.passwordNew2,
				},
				{
					headers: {
						if_id: Constants.PREPAY_REGISTER_PASSWORD,
						'Content-Type': 'application/json',
						token: cookies.token,
					},
				}
			)
			.then(function (response) {
				if (response.data.result_code != '0000') {
					swal({
						text: response.data.result_message,
						button: {
							text: '확인',
						},
					});
					if (response.data.result_code == '1004') {
						removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
					}
					Swal.close();
				} else {
					swal({
						text: response.data.result_message,
						button: {
							text: '확인',
						},
					});
					removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
					navigate('/login', { replace: false });
					Swal.close();
				}
			})
			.catch(function (error) {
				swal({
					text: '미지정 오류',
					button: {
						text: '확인',
					},
				});
				Swal.close();
			});
		// .then(function () {
		// 	// 항상 실행
		// });
	}

	return (
		<Page className={classes.root}>
			<Box display="flex" flexDirection="column" height="100%" justifyContent="center">
				<Container maxWidth="sm">
					<Typography variant="h2" component="h1" gutterBottom align="center" color="primary">
				
					</Typography>
				</Container>
				<Box p={3} />
				<Container maxWidth="sm">
					<Formik
						initialValues={{
							uid: uid,
							password: '',
							passwordNew: '',
							passwordNew2: '',
						}}
						onSubmit={(value) => {
							registPassword(value);
						}}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values,
						}) => (
							<form onSubmit={handleSubmit}>
								<Box mb={3}>
									<Typography color="textPrimary" variant="h2">
										비밀번호 변경
									</Typography>
									<Typography color="textSecondary" gutterBottom variant="body2">
										Sign in on the internal platform
									</Typography>
								</Box>
								<Box mt={3} mb={1}></Box>
								<TextField
									error={Boolean(touched.email && errors.email)}
									fullWidth
									helperText={touched.email && errors.email}
									margin="normal"
									name="uid"
									onBlur={handleBlur}
									onChange={handleChange}
									type="uid"
									value={uid}
									variant="outlined"
									disabled
								/>
								<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText={touched.password && errors.password}
									label="비밀번호"
									margin="normal"
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									type="password"
									value={values.password}
									variant="outlined"
								/>
									<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText={touched.password && errors.password}
									label="새로운 비밀번호"
									margin="normal"
									name="passwordNew"
									onBlur={handleBlur}
									onChange={handleChange}
									type="password"
									value={values.passwordNew}
									variant="outlined"
								/>
									<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText={touched.password && errors.password}
									label="새로운 비밀번호 확인"
									margin="normal"
									name="passwordNew2"
									onBlur={handleBlur}
									onChange={handleChange}
									type="password"
									value={values.passwordNew2}
									variant="outlined"
								/>
								<Box my={2}>
									<Button color="primary" fullWidth size="large" type="submit" variant="contained">
										저장
									</Button>

									<Box mt={3} mb={1}></Box>
									<Typography color="textSecondary" gutterBottom variant="caption">
										Copyrights © 2023 PAYSIS All Rights Reserved.
									</Typography>
								</Box>

								{/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography> */}
							</form>
						)}
					</Formik>
				</Container>
			</Box>
		</Page>
	);


};

export default RegisterPasswordView;
