import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import {
	Box,
	Button,
	Card,
	CardHeader,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	Chip,
	ListItemText,
	makeStyles,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import dummyOrders from '../../../dummy/prepayOrders';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { numberWithCommas } from '../../../common/numberWithCommas';
import { dateYYYYMMDD } from '../../../common/dateYYYYMMDD';
import { dateTime } from '../../../common/dateTime';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Constants from '../../../common/Constants';

const useStyles = makeStyles({
	root: {
		height: '100%',
	},
});

const LastestOrders = ({ className, ...rest }) => {
	const classes = useStyles();
	// const [products] = useState(data);
	const [products, setProducts] = useState([]);

	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies();
	const api = () => {
		Swal.showLoading();
		axios
			.post(
				Constants.URL,
				{
					last_seq: 0,
				},
				{
					headers: {
						if_id: Constants.PREPAY_REQUEST_HIST,
						'Content-Type': 'application/json',
						token: cookies.token,
					},
				}
			)
			.then(function (response) {
				if (response.data.result_code != '0000') {
					swal({
						text: response.data.result_message,
						button: {
							text: '확인',
						},
					});
					if (response.data.result_code == '1004') {
						removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
						navigate('/login', { replace: false });
					}
					Swal.close();
				} else {
					setProducts(response.data.prestmt);
					console.log(products);
					Swal.close();
				}
			})
			.catch(function (error) {
				swal({
					text: '미지정 오류',
					button: {
						text: '확인',
					},
				});
				Swal.close();
			});
	};

	useEffect(() => {
		api();
	}, []);

	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			{/* <CardHeader
        subtitle={`${products.length} in total`}
        title="최근지급내역"
      /> */}
			<ListItem>
				<div style={{ float: 'left', width: '50%' }}>최근지급내역 </div>
				<div align="right" style={{ float: 'right', width: '50%' }}>
					<Chip
						label="지급내역 더보기"
						color="secondary"
						variant="outlined"
						clickable={true}
						onClick={() => {
							navigate('/app/listOrders', { replace: false });
						}}
					/>
				</div>
			</ListItem>
			<Divider />

			{products.length > 0 ? (
				<List>
					{products.map((product, i) => (
						<ListItem divider={i < products.length - 1} key={product.seq}>
							<div style={{ width: '100%' }}>
								<div style={{ float: 'left', width: '70%' }}>
									<div>
										{dateYYYYMMDD(product.prestmt_dt)} {dateTime(product.prestmt_tm)}
									</div>
								</div>
								<div align="right" style={{ float: 'right', width: '30%' }}>
									{(function () {
										if (product.rslt === '지급 완료') {
											return <div style={{ color: 'blue' }}>{product.rslt}</div>;
										} else if (product.rslt === '지급 실패') {
											return <div style={{ color: 'red' }}>{product.rslt}</div>;
										} else {
											return <div style={{ color: 'green' }}>{product.rslt}</div>;
										}
									})()}
								</div>
								<div align="right" style={{ float: 'right', width: '80%' }}>
									<br></br>
									<div>
										<h3>{numberWithCommas(product.amt)}원</h3>
									</div>
								</div>
							</div>
						</ListItem>
					))}
				</List>
			) : (
				<List>
					<div style={{ textAlign: 'center' }}>지급내역이 존재하지 않습니다.</div>
				</List>
			)}
		</Card>
	);
};

LastestOrders.propTypes = {
	className: PropTypes.string,
};

export default LastestOrders;
