import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import './mixins/chartjs';
import theme from './theme';
import routes from './routes';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export const MyContext = React.createContext('');

const App = () => {
	// route 파일
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const [isLoggedIn, setIsloggedIn] = useState(false);

	const routing = useRoutes(routes(cookies.token !== undefined ? true : false));

	// const [money, setMoney] = useState(77000);
	const [msinfo, setMsinfo] = useState({});

	return (
		<MyContext.Provider value={{ msinfo, setMsinfo }}>
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				{routing}
			</ThemeProvider>
		</MyContext.Provider>
	);
};

export default App;
