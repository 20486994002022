import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Badge, Box, Hidden, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../../components/Logo';
import { TopBarTitle } from '../../common/TopBarTitle';

const useStyles = makeStyles(() => ({
	root: {},
	avatar: {
		width: 60,
		height: 60,
	},
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const [notifications] = useState([]);
	const { pathname } = useLocation();

	useEffect(() => {
		console.log('appBar Effect');
	}, ['AppBar']);
	return (
		<AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
			<Toolbar>
				<RouterLink to="/app/request">
					<Logo />
				</RouterLink>
				<Box flexGrow={1} />
				<h3>{TopBarTitle(pathname)}</h3>
				<Box flexGrow={1} />
				<Hidden mdDown>
					<IconButton color="inherit">
						<Badge badgeContent={notifications.length} color="primary" variant="dot">
							<NotificationsIcon />
						</Badge>
					</IconButton>
					<IconButton color="inherit">
						<InputIcon />
					</IconButton>
				</Hidden>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={onMobileNavOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBar;
