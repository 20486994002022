export function TopBarTitle(pathname) {
	if (pathname == '/app/request') {
		return '선지급 요청';
	} else if (pathname == '/app/listOrders') {
		return '지급내역';
	} else if (pathname == '/app/requestSuccess') {
		return '선지급 요청 결과';
	}else if (pathname == '/app/registerPassword') {
		return '비밀번호 변경';
	}
}
