import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
	Card,
	CardContent,
	Grid,
	Typography,
	colors,
	makeStyles,
	Button,
	TextField,
	Divider,
	CardHeader,
	List,
	ListItemText,
	ButtonBase,
	InputAdornment,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { MyContext } from '../../../App';
import { numberWithCommas } from '../../../common/numberWithCommas';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Constants from '../../../common/Constants';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
	},
}));

const RequestList = ({ className, ...rest }) => {
	const classes = useStyles();
	const [cookies, setCookie, removeCookie] = useCookies();
	const { msinfo, setMsinfo } = useContext(MyContext);
	const [amt, setAmt] = useState();
	const [realAmt, setRealAmt] = useState(0);
	const date = new Date();
	var disabled = false;
	var hoursMinutes =false;
	if ((date.getHours() == 23 && date.getMinutes() > 29) || (date.getHours() == 0 && date.getMinutes() < 30)) {
		hoursMinutes = true;
	} else {
		hoursMinutes = false;
	}
	const p_amt = msinfo.prestmt_p_amt;
	const p_fee = msinfo.prestmt_fee;
	const p_svc_lmt = msinfo.prestmt_svc_lmt;
	const navigate = useNavigate();

	if (msinfo.prestmt_type) {
		swal({
			text: '지급 중 일시에는 카드매출 선지급 신청을 할 수 없습니다.',
			button: {
				text: '확인',
			},
		});
		disabled = true;
	} else {
		disabled = false;
	}

	if (hoursMinutes) {
		disabled = true;
		swal({
			text: '23:30 ~ 00:30 까지 선지급 서비스 이용이 불가합니다.',
			button: {
				text: '확인',
			},
		});
	}

	const onChange = (e) => {
		var regex = /[^0-9]/g;

		if (regex.test(e.target.value)) {
			swal({
				text: '선지급 요청 금액은 숫자만 입력 가능합니다',
				button: {
					text: '확인',
				},
			});
			e.target.value = '';
			setRealAmt(0);
			return false;
		}
		if (e.target.value <= p_amt) {
			if (e.target.value >= p_fee) {
				if (e.target.value == '') {
					setRealAmt(0);
				} else {
					setRealAmt(e.target.value - p_fee);
				}
			} else {
				setRealAmt(0);
			}
		} else {
			swal({
				text: '선지급 요청 금액이 선지급가능액 보다 많습니다.',
				button: {
					text: '확인',
				},
			});
			e.target.value = '';
			setRealAmt(0);
		}
	};

	function requestMoneyApi() {
		if (realAmt == 0) {
			swal({
				text: '선지급 요청 금액을 확인해주세요',
				button: {
					text: '확인',
				},
			});
		} else if (realAmt + p_fee > p_svc_lmt) {
			swal({
				text: '선지급 요청 금액은' +p_svc_lmt+ '원 이하여야 합니다',
				button: {
					text: '확인',
				},
			});
		}else if ((date.getHours() == 23 && date.getMinutes() > 29) || (date.getHours() == 0 && date.getMinutes() < 30)) {
			swal({
				text: '23:30 ~ 00:30 까지 선지급 서비스 이용이 불가합니다.',
				button: {
					text: '확인',
				},
			});
		}else {
			swal('선지급 요청을 진행하시겠습니까?', {
				buttons: {
					cancel: '취소',
					확인: true,
				},
			}).then((value) => {
				if (value == '확인') {
					Swal.showLoading();
					axios
						.post(
							Constants.URL,
							{
								req_amt: realAmt,
							},
							{
								headers: {
									if_id: Constants.PREPAY_REQUEST,
									'Content-Type': 'application/json',
									token: cookies.token,
								},
								// timeout: 1000,
							}
						)
						.then(function (response) {
							if (response.data.result_code == '1004' && response.data.result_code != '0000') {
								Swal.close();
								removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
								navigate('/login', { replace: false });
							} else if (
								response.data.result_code != '1004' &&
								response.data.result_code != '0000'
							) {
								Swal.close();
								swal({
									text: response.data.result_message,
									button: {
										text: '확인',
									},
								}).then(() => {
									navigate(0);
								});
							} else {
								Swal.close();
								navigate('/app/requestSuccess', {
									state: {
										prestmt_p_amt_add: p_amt - realAmt - p_fee,
										prestmt_req_amt: realAmt + p_fee,
										prestmt_real_amt: realAmt,
										prestmt_fee: p_fee,
									},
								});
							}
						})
						.catch(function (error) {
							Swal.close();
							swal({
								text: '서비스 지연중입니다.',
								button: {
									text: '확인',
								},
							}).then(() => {
								navigate(0);
							});
						});
				}
			});
		}
	}

	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			<CardHeader title="카드매출 지급 신청" subtitle="선지급요청"></CardHeader>
			<Divider />
			<CardContent>
				<List>
					<ListItemText>
						<Grid container justify="space-between" spacing={2}>
							<Grid item xs={6}>
								선지급 요청 금액
							</Grid>
							<Grid item xs={6}>
								{/* <InputLabel htmlFor="standard-adornment-amount">선지급 요청 금액</InputLabel>
                <OutlinedInput fullWidth
                  id="standard-adornment-amount"
                  startAdornment={<InputAdornment position="start">￦</InputAdornment>}
                /> */}
								<TextField
									error
									value={amt}
									onChange={onChange}
									id="standard-basic"
									fullWidth
									FormHelperTextProps={{
										style: { textAlign: 'right' },
									}}
									helperText={'1회 요청한도 : ' +numberWithCommas(p_svc_lmt)+ '원'}
									type="text"
									placeholder="0"
									inputProps={{ style: { textAlign: 'right' } }}
									InputProps={{
										endAdornment: <InputAdornment position="end">원</InputAdornment>,
									}}
								></TextField>
							</Grid>
						</Grid>
						<Grid container justify="space-between" spacing={2}>
							<Grid item xs={6}>
								실 입금 예정액
							</Grid>
							<Grid align="right" item xs={6}>
								{numberWithCommas(realAmt)}원
							</Grid>
						</Grid>
						<Grid container justify="space-between" spacing={2} alignContent="flex-end">
							<Grid item xs={6}>
								수수료
							</Grid>
							<Grid align="right" item xs={6}>
								{numberWithCommas(msinfo.prestmt_fee)}원
							</Grid>
						</Grid>
						<Divider />
						<Grid container spacing={2}>
							<Grid item xs={12} sm container>
								<Grid item xs container direction="column" spacing={2}>
									<Grid item xs>
										<Typography gutterBottom variant="subtitle1">
											입금 계좌
										</Typography>
										<Typography variant="body2" gutterBottom>
											{msinfo.prestmt_bank_nm} {msinfo.prestmt_acnt_no}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</ListItemText>
				</List>

				<Button
					color="primary"
					disabled={disabled}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					onClick={requestMoneyApi}
				>
					카드매출 선지급 신청
				</Button>
				{/* <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          <ArrowDownwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            12%
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Since last month
          </Typography>
        </Box> */}
			</CardContent>
		</Card>
	);
};

RequestList.propTypes = {
	className: PropTypes.string,
};

export default RequestList;
