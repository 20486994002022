import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	Grid,
	Typography,
	colors,
	makeStyles,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { render } from '@testing-library/react';
import { MyContext } from '../../../App';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Constants from '../../../common/Constants';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
	},
}));

const MutualName = ({ className, ...rest }) => {
	const classes = useStyles();
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();
	const { msinfo, setMsinfo } = useContext(MyContext);
	function test() {
		Swal.showLoading();
		axios
			.post(
				Constants.URL,
				{},
				{
					headers: {
						if_id: Constants.PREPAY_MAIN_INFO,
						'Content-Type': 'application/json',
						token: cookies.token,
					},
				}
			)
			.then(function (response) {
				if (response.data.result_code != '0000') {
					swal({
						text: response.data.result_message,
						button: {
							text: '확인',
						},
					});
					if (response.data.result_code == '1004') {
						removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
						navigate('/login', { replace: false });
					}
					Swal.close();
				} else {
					setMsinfo(response.data);
					Swal.close();
				}
			})
			.catch(function (error) {
				swal({
					text: '미지정 오류',
					button: {
						text: '확인',
					},
				});
				Swal.close();
			});
		// .then(function () {
		// 	// 항상 실행
		// });
	}

	useEffect(() => {
		test();
	}, []);

	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			<CardContent>
				<Grid container justify="space-between" spacing={2}>
					<Grid item xs={3}>
						상호명
					</Grid>
					<Grid align="right" item xs={9}>
						{msinfo.prestmt_co_nm}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

MutualName.propTypes = {
	className: PropTypes.string,
};

export default MutualName;
