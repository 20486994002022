import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	Grid,
	Typography,
	colors,
	makeStyles,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { render } from '@testing-library/react';
import { MyContext } from '../../../App';
import { numberWithCommas } from '../../../common/numberWithCommas';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
	},
	avatar: {
		backgroundColor: colors.red[600],
		height: 56,
		width: 56,
	},
	differenceIcon: {
		color: colors.red[900],
	},
	differenceValue: {
		color: colors.red[900],
		marginRight: theme.spacing(1),
	},
}));

const Status = ({ className, ...rest }) => {
	const classes = useStyles();
	const { msinfo, setMsinfo } = useContext(MyContext);

	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			<CardContent>
				<Grid container justify="space-between" spacing={2}>
					<Grid align="left" style={{ fontSize: '20px' }} item xs={7}>
						선지급가능액
					</Grid>
					<Grid align="right" style={{ fontSize: '20px' }} item xs={5}>
						{numberWithCommas(String(msinfo.prestmt_p_amt))}원
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

Status.propTypes = {
	className: PropTypes.string,
};

export default Status;
