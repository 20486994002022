import React, {Component} from "react";

import {
  Route, NavLink, HashRouter
} from "react-router-dom";

import Home from "./Home";
import Stuff from "./Stuff";
import Contact from "./Contact";
// import Login from "./Login";
import SignIn from "./pages/account/SignIn";
import PrePay from "./pages/prepay/SubmitPrepay";

class Main extends Component {
  render() {
    return (
      <div>
        <div>
          <ul className="header">
            <li><NavLink exact to="/">Home</NavLink></li>
            <li><NavLink to="/stuff">Stuff</NavLink></li>
            <li><NavLink to="/contact">Contact</NavLink></li>
            <li><NavLink to="/login">Login</NavLink></li>
            <li><NavLink to="/signin">로그인</NavLink></li>
            <li><NavLink to="/prepay">선지급 신청</NavLink></li>
          </ul>
          <div className="content">
            <Route exact path="/" component={Home}/>
            <Route path="/stuff" component={Stuff}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/login" component={SignIn}/>
            <Route path="/signin" component={SignIn}/>
            <Route path="/prepay" component={PrePay}/>
          </div>
          <div className="content"></div>
        </div>
      </div>
    )
  }
}

export default Main;
