import React from 'react';
import { Navigate } from 'react-router-dom';

//import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import PrepayLayout from './layouts/PrepayLayout';

// import AccountView from './views/account/AccountView';
// import CustomerListView from './views/customer/CustomerListView';
import DashboardView from './views/reports/DashboardView';
import LoginView from './views/auth/LoginView';
import RegisterPasswordView from './views/auth/RegisterPasswordView';
import NotFoundView from './views/errors/NotFoundView';
import MobileChrome from './views/errors/MobileChrome';
// import ProductListView from './views/product/ProductListView';
// import RegisterView from './views/auth/RegisterView';
import SettingsView from './views/settings/SettingsView';
import PrepayRequestView from './views/prepay/PrepayRequestView';
import DateListOrders from './views/prepay/PrepayRequestView/DateListOrders';
import RequestSuccess from './views/prepay/PrepayRequestView/RequestSucces';

const routes = (isLoggedIn) => [
	{
		path: 'app',
		element: isLoggedIn ? <PrepayLayout /> : <Navigate to="/login" />,
		children: [
			{ path: 'request', element: <PrepayRequestView /> },
			{ path: 'registerPassword', element: <RegisterPasswordView /> },
			// { path: 'customers', element: <CustomerListView /> },
			{ path: 'listOrders', element: <DateListOrders /> },
			{ path: 'requestSuccess', element: <RequestSuccess /> },
			// { path: 'settings', element: <SettingsView /> },
			{ path: '*', element: <Navigate to="/404" /> },
			{ path: 'dashboard', element: <DashboardView /> },
		],
		title: '서비스',
	},
	// {
	//   path: 'app_origin',
	//   element: <DashboardLayout />,
	//   children: [
	//     { path: 'account', element: <AccountView /> },
	//     { path: 'customers', element: <CustomerListView /> },
	//     { path: 'dashboard', element: <DashboardView /> },
	//     { path: 'products', element: <ProductListView /> },
	//     { path: 'settings', element: <SettingsView /> },
	//     { path: '*', element: <Navigate to="/404" /> }
	//   ]
	// },
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{ path: 'login', element: <LoginView /> },
			
			// { path: 'register', element: <RegisterView /> },
			{ path: '404', element: <NotFoundView /> },
			{ path: 'mobileChrome', element: <MobileChrome /> },
			{ path: '/', element: <Navigate to="/login" /> },
			{ path: '*', element: <Navigate to="/404" /> },
		],
	},
];

export default routes;
