import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
	Container,
	Avatar,
	Box,
	Card,
	CardContent,
	Grid,
	Typography,
	colors,
	makeStyles,
	Divider,
	Button,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { numberWithCommas } from '../../../common/numberWithCommas';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
}));

const RequestSuccess = ({ className, ...rest }) => {
	const classes = useStyles();
	const money = useLocation();
	console.log(money);

	const navigate = useNavigate();

	return (
		<Page className={classes.root}>
			<Container maxWidth={false}>
				<Card className={clsx(classes.root, className)} {...rest}>
					<CardContent>
						<div>
							<h4>선지급 신청이 정상적으로 완료되었습니다.</h4>
						</div>
						<br></br>
						<div style={{ color: 'red', fontSize: '12px' }}>
							※은행에 따라 선지급 요청 후 지급 완료까지는 약 2~3분 내외의 시간이 소요됩니다.
						</div>
					</CardContent>
				</Card>
			</Container>
			<br></br>
			<Container maxWidth={false}>
				<Card className={clsx(classes.root, className)} {...rest}>
					<CardContent>
						<Grid container justify="space-between" spacing={2}>
							<Grid item xs={7}>
								선지급 요청 금액
							</Grid>
							<Grid item xs={5}>
								{numberWithCommas(money.state.prestmt_req_amt)}원
							</Grid>
						</Grid>
						<Grid container justify="space-between" spacing={2}>
							<Grid item xs={7}>
								실 입금 예정액
							</Grid>
							<Grid item xs={5}>
								{numberWithCommas(money.state.prestmt_real_amt)}원
							</Grid>
						</Grid>
						<Grid container justify="space-between" spacing={2}>
							<Grid item xs={7}>
								수수료
							</Grid>
							<Grid item xs={5}>
								{numberWithCommas(money.state.prestmt_fee)}원
							</Grid>
						</Grid>
						<br></br>
						<Grid container justify="space-between" spacing={2}>
							<Grid item xs={7}>
								추가 선지급 가능액
							</Grid>
							<Grid item xs={5}>
								{numberWithCommas(money.state.prestmt_p_amt_add)}원
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Container>
			<br></br>
			<Container maxWidth={false}>
				<Card className={clsx(classes.root, className)} {...rest}>
					<CardContent>
						<Button
							color="primary"
							// disabled={isSubmitting}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							onClick={() => {
								navigate('/app/request', { replace: false });
							}}
						>
							확인
						</Button>
					</CardContent>
				</Card>
			</Container>
		</Page>
	);
};

RequestSuccess.propTypes = {
	className: PropTypes.string,
};

export default RequestSuccess;
