import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import {
	Box,
	Button,
	Card,
	CardHeader,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	Chip,
	ListItemText,
	makeStyles,
	TextField,
	CardContent,
	ButtonGroup,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import dummyOrders from '../../../dummy/prepayOrders';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { numberWithCommas } from '../../../common/numberWithCommas';
import Page from '../../../components/Page';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { dateYYYYMMDD } from '../../../common/dateYYYYMMDD';
import { dateTime } from '../../../common/dateTime';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { RepeatOneSharp, RestoreRounded } from '@material-ui/icons';
import Constants from '../../../common/Constants';

const useStyles = makeStyles((theme) => ({
	root: {
		WebkitOverflowScrolling: 'touch',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	textField: {
		width: 300,
	},
}));

const getFormatDate = (date) => {
	var year = date.getFullYear();
	var month = 1 + date.getMonth();
	month = month >= 10 ? month : '0' + month;
	var day = date.getDate();
	day = day >= 10 ? day : '0' + day;
	return year + '-' + month + '-' + day;
};

const ListOrders = ({ className, ...rest }) => {
	const classes = useStyles();
	// const [products] = useState(data);

	const currentDate = new Date();
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies();
	const [startDate, setStartDate] = useState(
		getFormatDate(new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000))
	);
	const [endDate, setEndDate] = useState(getFormatDate(currentDate));
	const [alignment, setAlignment] = useState();

	// 날짜 버튼 클릭, 기간 변경 기능7

	const handleAlignment = (event, newAlignment) => {
		if (newAlignment == 'week') {
			let weekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
			setStartDate(getFormatDate(weekAgo));
			setEndDate(getFormatDate(currentDate));
		} else if (newAlignment == 'month1') {
			let oneMonthAgo = new Date(
				new Date().getFullYear(),
				new Date().getMonth() - 1,
				new Date().getDate()
			);
			setStartDate(getFormatDate(oneMonthAgo));
			setEndDate(getFormatDate(currentDate));
		} else if (newAlignment == 'month3') {
			let threeMonthAgo = new Date(
				new Date().getFullYear(),
				new Date().getMonth() - 3,
				new Date().getDate()
			);
			setStartDate(getFormatDate(threeMonthAgo));
			setEndDate(getFormatDate(currentDate));
		}
		setAlignment(newAlignment);
	};

	const startDateChange = (e) => {
		handleAlignment();
		setStartDate(e.target.value);
	};
	const endDateChange = (e) => {
		setEndDate(e.target.value);
	};
	const dateaReplace = (x) => {
		if (startDate == undefined || endDate == undefined) {
			return x;
		} else {
			return x.replace(/\-/g, '');
		}
	};
	const [check, setCheck] = useState(true);

	const [last_seq, setLast_seq] = useState(0);
	const [products, setProducts] = useState([]);

	const listOrdersApi = () => {
		if (check) {
			Swal.showLoading();
			axios
				.post(
					Constants.URL,
					{
						last_seq: last_seq,
						bizFrom: dateaReplace(startDate),
						bizTo: dateaReplace(endDate),
					},
					{
						headers: {
							if_id: Constants.PREPAY_REQUEST_HIST,
							'Content-Type': 'application/json',
							token: cookies.token,
						},
					}
				)
				.then(function (response) {
					console.log(response.data.prestmt);
					if (response.data.result_code != '0000') {
						swal({
							text: response.data.result_message,
							button: {
								text: '확인',
							},
						});
						if (response.data.result_code == '1004') {
							removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
							navigate('/login', { replace: false });
						}
						Swal.close();
					} else {
						if (last_seq == 0) {
							setProducts(response.data.prestmt);
						} else {
							setProducts(products.concat(response.data.prestmt));
							// setProducts(...products, response.data.prestmt);
						}
						if (response.data.prestmt.length == 0) {
							setCheck(false);
						}
						Swal.close();
					}
				})
				.catch(function (error) {
					swal({
						text: '미지정 오류',
						button: {
							text: '확인',
						},
					});
					Swal.close();
				});
		}
	};

	const serchList = (e) => {
		console.log(last_seq);
		setCheck(true);
		setLast_seq(0);
		if (last_seq == 0) {
			listOrdersApi();
		}
	};

	window.onscroll = function (e) {
		//실행할 로직 (콘텐츠 추가)
		setTimeout(() => {
			if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
				setLast_seq(last_seq + 20);
			}
		}, 500);
	};

	// const _infinitieScroll = () => {
	// 	let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
	// 	let scrolltop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
	// 	let clientHeight = document.documentElement.clientHeight;

	// 	console.log(scrollHeight, scrolltop, clientHeight);
	// 	if (scrolltop + clientHeight === scrollHeight && check) {
	// 		setLast_seq(last_seq + 3);
	// 	}
	// };

	// useEffect(() => {
	// 	window.addEventListener('scroll', _infinitieScroll, true);
	// 	return () => {
	// 		window.removeEventListener('scroll', _infinitieScroll, true);
	// 	};
	// }, [_infinitieScroll]);

	useEffect(() => {
		document.activeElement.blur();
		listOrdersApi();
	}, [last_seq]);
	useEffect(() => {}, []);

	return (
		<Page className={classes.root}>
			<Card className={clsx(classes.root, className)} {...rest}>
				<ListItem>
					<TextField
						id="startDate"
						label="시작일"
						type="date"
						// defaultValue={getFormatDate(dateValue)}
						className={classes.textField}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{ style: { fontSize: 13 } }}
						value={startDate}
						onChange={startDateChange}
					></TextField>
					&nbsp; &nbsp; &nbsp; &nbsp;
					<TextField
						id="endDate"
						label="종료일"
						type="date"
						// defaultValue={getFormatDate(dateValue)}
						className={classes.textField}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{ style: { fontSize: 13 } }}
						value={endDate}
						onChange={endDateChange}
					></TextField>
				</ListItem>
				<div style={{ textAlign: 'center' }}>
					<ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment}>
						<ToggleButton
							style={{ margin: '15px', color: 'blue', border: '0.5px solid', lineHeight: '0.5px' }}
							value="week"
						>
							일주일
						</ToggleButton>
						<ToggleButton
							style={{ margin: '15px', color: 'blue', border: '0.5px solid', lineHeight: '0.5px' }}
							value="month1"
						>
							1개월
						</ToggleButton>
						<ToggleButton
							style={{ margin: '15px', color: 'blue', border: '0.5px solid', lineHeight: '0.5px' }}
							value="month3"
						>
							3개월
						</ToggleButton>
					</ToggleButtonGroup>
				</div>

				<ListItem>
					<Button
						color="primary"
						// disabled={isSubmitting}
						fullWidth
						size="large"
						variant="contained"
						onClick={serchList}
					>
						조회하기
					</Button>
				</ListItem>
			</Card>
			<br></br>

			<Card className={clsx(classes.root, className)} {...rest}>
				<ListItem>
					<div style={{ float: 'left' }}>지급내역 </div>
				</ListItem>
				<Divider />
				{products.length > 0 ? (
					<List>
						{products.map((product, i) => (
							<ListItem divider={i < products.length - 1} key={product.seq}>
								<div style={{ width: '100%' }}>
									<div style={{ float: 'left', width: '70%' }}>
										<div>
											{dateYYYYMMDD(product.prestmt_dt)} {dateTime(product.prestmt_tm)}
										</div>
									</div>
									<div align="right" style={{ float: 'right', width: '30%' }}>
										{(function () {
											if (product.rslt === '지급 완료') {
												return <div style={{ color: 'blue' }}>{product.rslt}</div>;
											} else if (product.rslt === '지급 실패') {
												return <div style={{ color: 'red' }}>{product.rslt}</div>;
											} else {
												return <div style={{ color: 'green' }}>{product.rslt}</div>;
											}
										})()}
									</div>
									<div align="right" style={{ float: 'right', width: '80%' }}>
										<br></br>
										<div>
											<h3>{numberWithCommas(product.amt)}원</h3>
										</div>
									</div>
								</div>
							</ListItem>
						))}
					</List>
				) : (
					<List>
						<div style={{ textAlign: 'center' }}>지급내역이 존재하지 않습니다.</div>
					</List>
				)}
			</Card>
		</Page>
	);
};

ListOrders.propTypes = {
	className: PropTypes.string,
};

export default React.memo(ListOrders);
