import React from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import Page from '../../components/Page';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		height: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	image: {
		marginTop: 30,
		marginBottom: 30,
		display: 'inline-block',
		maxWidth: '100%',
		width: '5em',
	},
}));

const NotFoundView = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root} title="404">
			<Box display="flex" flexDirection="column" height="100%" justifyContent="center">
				<Container maxWidth="md">
					<br></br>
					<br></br>
					<Typography variant="h2" component="h1" gutterBottom align="center">
						카드매출 선지급 서비스
						<p>주식회사 페이시스</p>
					</Typography>
					<Box textAlign="center">
						<img
							alt="Under development"
							className={classes.image}
							src="/static/images/logo192.png"
						/>
					</Box>
					<Typography align="center" variant="h4">
						선지급 서비스는 모바일 크롬
					</Typography>
					<Typography align="center" variant="h4">
						환경에서만 서비스를 제공합니다.
					</Typography>

					<br></br>
					<Typography align="center" style={{ color: 'red' }} color="textPrimary" variant="h4">
						모바일 크롬 환경으로
					</Typography>
					<Typography align="center" style={{ color: 'red' }} color="textPrimary" variant="h4">
						접속해주시기 바랍니다.
					</Typography>
					<br></br>
					<br></br>
					<Typography align="center" variant="h4">
						<a href="https://www.google.co.kr/chrome/browser/desktop/" target="_blank">
							<img src="https://www.google.com/chrome/static/images/chrome-logo.svg"></img>크롬
							브라우저 다운로드
						</a>
					</Typography>

					<br></br>
					<Typography align="center" color="textSecondary" gutterBottom variant="caption">
						Copyrights © 2023 PAYSIS All Rights Reserved.
					</Typography>
				</Container>
			</Box>
		</Page>
	);
};

export default NotFoundView;
