import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation  ,useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	Avatar,
	Box,
	Button,
	Divider,
	Drawer,
	Hidden,
	List,
	Typography,
	makeStyles,
} from '@material-ui/core';
import LocalAtm from '@material-ui/icons/LocalAtm';
import ListIcon from '@material-ui/icons/List';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOpen from '@material-ui/icons/LockOpen';
import NavItem from './NavItem';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';
import Constants from '../../../common/Constants';

const items = [
	{
		href: '/app/request',
		title: '선지급 요청',
		icon: LocalAtm,
	},
	{
		href: '/app/listOrders',
		title: '지급내역',
		icon: ListIcon,
	},
	{
		href: '/app/registerPassword',
		title: '비밀번호 변경',
		icon: LockOpen,
	},
	{
		href: '-',
		title: '로그아웃',
		icon: ExitToAppIcon,
	},
];

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 256,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: 'calc(100% - 64px)',
	},
	avatar: {
		cursor: 'pointer',
		width: 64,
		height: 64,
	},
}));

const NavBar = ({ onMobileClose, openMobile }) => {
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(['token']);

	const logOut = (value) => {
		if (value == '-') {
			Swal.showLoading();
			axios
				.post(
					Constants.URL,
					{},
					{
						headers: {
							if_id: Constants.PREPAY_LOGOUT,
							'Content-Type': 'application/json',
							token: cookies.token,
						},
					}
				)
				.then(function (response) {
					if (response.data.result_code != '0000') {
						swal({
							text: response.data.result_message,
							button: {
								text: '확인',
							},
						});
						Swal.close();
					} else {
						Swal.close();
					}
				})
				.catch(function (error) {
					swal({
						text: '미지정 오류',
						button: {
							text: '확인',
						},
					});
					Swal.close();
				})
				.then(function () {
					removeCookie('token', { path: '/', secure: false, sameSite: 'lax' });
					navigate('/login', { replace: false });
				});
		}
	};

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<Box p={2}>
				<List>
					{items.map((item) => (
						<NavItem
							href={item.href}
							key={item.title}
							title={item.title}
							icon={item.icon}
							onClick={() => logOut(item.href)}
						/>
					))}
				</List>
			</Box>
			{/* <Box flexGrow={1} /> */}
			{/* <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            See PRO version
          </Button>
        </Box>
      </Box> */}
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden mdDown>
				<Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
};

export default NavBar;
