import React from 'react';
import { Container, Grid, makeStyles, Button, Card } from '@material-ui/core';
import Page from '../../../components/Page';
// import Budget from './Budget';
import Status from './Status';
import LatestOrders from './LatestOrders';
import RequestList from './RequestList';
import MutualName from './MutualName';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: '100%',
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
}));

const PrepayRequestView = () => {
	const classes = useStyles();

	return (
		<Page className={classes.root}>
			<Container maxWidth={false}>
				<Grid container spacing={3} justify="center">
					<Grid
						item
						lg={6}
						// sm={6}
						sm={12}
						xl={3}
						xs={12}
					>
						<MutualName />
					</Grid>
					<Grid
						item
						lg={6}
						// sm={6}
						sm={12}
						xl={3}
						xs={12}
					>
						<Status />
					</Grid>
					<Grid item lg={12} md={12} xl={9} xs={12}>
						<RequestList />
					</Grid>
					<Grid item lg={12} md={12} xl={9} xs={12}>
						<LatestOrders />
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
};

export default PrepayRequestView;
