import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

//import './index.css';
import reportWebVitals from './reportWebVitals';
import Main from './Main';
// import App from './App';
// import Auth from './AuthApp';
import TestTheme from './App';
import ScrollToTop from './common/ScrollToTop';
ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			{/* <App /> */}
			{/* <Main/> */}
			<ScrollToTop />
			<TestTheme />
			{/* <Auth/> */}
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
