const Constants = {
  PROD_URL : "https://prestmtapi.paysis.co.kr/api/action.prestmt",
  URL : "https://dev-prestmtapi.paysis.co.kr/api/action.prestmt",
  PREPAY_REQUEST_URL : "api/action.prestmt",
  LOCAL_URL : "http://localhost:8082/api/action.prestmt",
  PREPAY_LOGIN: "IFPST001",
  PREPAY_MAIN_INFO: "IFPST002",
  PREPAY_REQUEST_HIST: "IFPST003",
  PREPAY_REQUEST: "IFPST004",
  PREPAY_LOGOUT: "IFPST005",
  PREPAY_REGISTER_PASSWORD: "IFPST006",
  PREPAY_SELECTUID: "IFPST007"
}



export default Constants;